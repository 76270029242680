<template>
  <v-select
    v-model="inputVal"
    label="Type"
    :items="data"
     item-text="name"
     item-value="key"
  ></v-select>
</template>

<script>
export default {
  props: ["value"],
  components: {
    //
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
    data:[{name:'Educator',key:'educator'},{name:'Family',key:'family'}]
  }),
  created() {
    //
  },
  mounted() {
  },
  methods: {
    //
  },
};
</script>