<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Account</v-card-title>
        <v-card-text>
          <template>
            <FormInput
              :value="form['name']"
              label="Full Name"
              :rules="nameRules"
              @input="form['name'] = $event"
            />
          </template>
          <template>
            <Type :value="form['accountType']" @input="form['accountType'] = $event"></Type>
          </template>
          <template>
            <MobileInput
              :value="mobile"
              label="Mobile number"
              :rules="mobileRules"
              @input="form['mobile'] = $event"
              @getCode="form['countryCode'] = $event"
            ></MobileInput>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/Account/FormInput";
import MobileInput from "@/components/Moderator/Account/MobileInput";
import Type from "@/components/Moderator/Account/Type";
import { mapState } from "vuex";
export default {
  props: ["data", "callbackSuccess"],
  components: {
    FormInput,
    MobileInput,
    Type,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      name: null,
      mobile: null,
      accountType: "educator",
      countryCode: "MYS",
    },
    fields: [],
    rules: {},
    mobileRules: [],
    nameRules: [],
    mobile: null,
    code: null,
    //EOC
  }),

  created() {
   
    //BOC:[api]
    this.api.url = `${this.$api.servers.sso}/api/v1/en/moderator/account/add`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      this.mobile = this.form.mobile;
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];
      this.nameRules = [(v) => !!v || "Name is required"];
      let self = this;
      setTimeout(function () {
      if (self.$refs.form.validate()) {
        self.submit();
      } else {
        self.api.callbackError("Please check the form.");
      }
    });
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>